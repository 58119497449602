import React from 'react';
import { Form } from 'react-bootstrap';

const URLGroup = ({ ids, formData, handleChange }) => {

  const labels = {
    videoUrl: 'Video Url',
    locationUrl: 'Location Url',
    // contactUrl: 'Contact Url',
    whatsappUrl: 'Whatsapp Url',
  };

  return ids. map((id) => (
    <Form.Group controlId={id} key={id}>
      <Form.Label>{labels[id]}</Form.Label>
      <Form.Control
        type="url"
        value={formData[id]}
        onChange={handleChange}
        placeholder={labels[id]}
        
      />
    </Form.Group>
  ));
};

export default URLGroup;
