// File: InputGroup.js

import React from 'react';
import { Form } from 'react-bootstrap';

const InputGroup = ({ formData, handleChange }) => {
  return (
    <>
      <Form.Group controlId="name">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          value={formData.name}
          onChange={handleChange}
          placeholder="Enter Your Name"
          required
        />
      </Form.Group>

      <Form.Group controlId="organization">
        <Form.Label>Organization</Form.Label>
        <Form.Control
          type="text"
          value={formData.organization}
          onChange={handleChange}
          placeholder="Enter Your Organization"
          required
        />
      </Form.Group>

      <Form.Group controlId="contactNumber">
        <Form.Label>ContactNumber</Form.Label>
        <Form.Control
          type="number"
          value={formData.contactNumber}
          onChange={handleChange}
          placeholder="Enter Your Contact Number"
          required
        />
      </Form.Group>

      <Form.Group controlId="contactEmail">
        <Form.Label>contactEmail</Form.Label>
        <Form.Control
          type="email"
          value={formData.contactEmail}
          onChange={handleChange}
          placeholder="Enter Your Email "
          required
        />
      </Form.Group>

      <Form.Group controlId="dateOfBirth">
        <Form.Label>Date of Birth</Form.Label>
        <Form.Control
          type="date"
          value={formData.dateOfBirth}
          onChange={handleChange}
          required
        />
      </Form.Group>
    </>
  );
};

export default InputGroup;
