// import React, { useState } from 'react';
// import { Container, Form, Button, Alert } from 'react-bootstrap';
// import {useNavigate } from 'react-router-dom';
// // import './LoginForm.css'; // Import CSS file for styling

// const LoginForm = ({onLogin }) => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [error, setError] = useState('');
//   const Navigate=useNavigate()

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const response = await fetch('http://localhost:3007/login', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({ email, password })
//       });

//       if (response.ok) {
//         const data = await response.json();
//         localStorage.setItem('token', data.token); // Store token in local storage for future API requests
//         setError('');
//         onLogin(data.token)
//         Navigate("/dashboard")
//         // Redirect or navigate to dashboard/home page after successful login
//         // Example: history.push('/dashboard');
//       } else {
//         const data = await response.json();
//         setError(data.error || 'Failed to login. Please check your credentials.');
//       }
//     } catch (error) {
//       console.error('Error logging in:', error);
//       setError('Failed to login. Please try again.');
//     }
//   };

//   return (
//     <Container className="mt-5">
//       <div className="login-form">
//         <h2>Login</h2>
//         <Form onSubmit={handleSubmit}>
//           <Form.Group controlId="email">
//             <Form.Label>Email address</Form.Label>
//             <Form.Control
//               type="email"
//               placeholder="Enter your email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               required
//             />
//           </Form.Group>

//           <Form.Group controlId="password">
//             <Form.Label>Password</Form.Label>
//             <Form.Control
//               type="password"
//               placeholder="Enter your password"
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//               required
//             />
//           </Form.Group>

//           {error && <Alert variant="danger">{error}</Alert>}

//           <Button variant="primary" type="submit" className='mt-3'>
//             Login
//           </Button>
//         </Form>
//       </div>
//     </Container>
//   );
// };

// export default LoginForm;

import React, { useEffect, useState } from "react";
import { Container, Form, Button, Alert, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
// import './LoginForm.css'; // Import CSS file for styling
import './Login.css'

const LoginForm = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [active, setActive] = useState(false);
  const navigate = useNavigate();
  const [websiteData, setWebsiteData] = useState(null);
  const websiteUrl = 'your-website-url'; 

  //   const handleSubmit = async (e) => {
  //     e.preventDefault();

  //     try {
  //       const response = await fetch('http://localhost:3007/login', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({ email, password }),
  //       });

  //       if (response.ok) {
  //         const data = await response.json();
  //         localStorage.setItem('token', data.token);
  //         setError('');
  //         onLogin(data.token);
  //         navigate("/dashboard");
  //       } else {
  //         const data = await response.json();
  //         setError(data.error || 'Failed to login. Please check your credentials.');
  //       }
  //     } catch (error) {
  //       console.error('Error logging in:', error);
  //       setError('Failed to login. Please try again.');
  //     }
  //   };

  const onChangeCaptcha = () => {
    setActive(!active);
  };

  // Inside LoginForm component, after successful login
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://api.emedha.in/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      localStorage.setItem('loginEmail',email)

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("token", data.token);
        onLogin(data.token); 
       
        alert(data.message);
        navigate("/form"); 
      } else {
        const data = await response.json();
        setError(data.error || "Login failed. Please try again.");
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setError("Login failed. Please try again.");
    }
  };

  // useEffect(() => {
  //   // Check if there's form data in localStorage
  //   const formData = JSON.parse(localStorage.getItem('formData'));

  //   if (formData) {
  //     // Redirect to FormComponent if form data exists
  //     navigate('/form');
  //   } else {
    
   
        
  //     // Redirect to HomeComponent if no form data exists
  //     navigate(`/${/:websiteUrl}`);
  //   }
  // }, [navigate]);



  return (
  
    <Container className="mt-5">
    <Row className="justify-content-md-center">
      <Col xs={12} md={6} className="login-form">
      <div className="text-center mb-4 d-flex flex-row align-items-center justify-content-start">
            {/* Displaying the logo */}
            <img src="https://emedha.com/assets/img/logo3.png" alt="Emedha Logo" className="logo-img" />
        

          <h2 className="text-center mb-4 ">Login</h2>
          </div>

        <Form onSubmit={handleLogin}>
          <Form.Group controlId="email">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Form.Group>

          <div className="text-center mt-3 mb-2 g-recaptcha">
            <ReCAPTCHA
              sitekey="6LcKzpknAAAAAM3IGeFPi75Qh1HZDkuY9X7wDLfo"
              onChange={onChangeCaptcha}
            />
          </div>

          {error && <Alert variant="danger">{error}</Alert>}

          <Button variant="primary" type="submit" className="btn-login mt-3">
            Login
          </Button>

          <div className="mt-3 d-flex flex-row align-items-center justify-content-center">
            <p className="m-2">
              <a href="/forgot-password">Forgot Password?</a>
            </p>
            <p className="m-2 pl-2">
              New user? <a href="/">Sign Up</a>
            </p>
          </div>
        </Form>
      </Col>
    </Row>
  </Container>
  );
};

export default LoginForm;
