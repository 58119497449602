import React from 'react';
import { Form } from 'react-bootstrap';

const ImageUpload = ({ handleFileChange }) => {
  return (
    <Form.Group controlId="imageFile">
      <Form.Label>Upload Logo File</Form.Label>
      <Form.Control
        type="file"
        
        accept="image/*"
        onChange={handleFileChange}
        
      />
    </Form.Group>
  );
};

export default ImageUpload;
