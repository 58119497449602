import React from 'react';
import { Form } from 'react-bootstrap';

const Gallery = ({ handleFileChangee,handleFileChangeee }) => {
  return (
    <>
    <Form.Group controlId="imageFile1">
      <Form.Label>Upload imageFile</Form.Label>
      <Form.Control
        type="file"
        
        accept="image/*"
        onChange={handleFileChangee}
        
      />
      </Form.Group>
      <Form.Group controlId="imageFile2">
      <Form.Label>Upload imageFile</Form.Label>
      <Form.Control
        type="file"
        
        accept="image/*"
        onChange={handleFileChangeee}
        
      />
      </Form.Group>

    
    {/* <Form.Group controlId="imageFile2">
      <Form.Label>Upload imageFile</Form.Label>
      <Form.Control
        type="file"
        
        accept="image/*"
        onChange={handleFileChange}
        required
      />
       </Form.Group> */}
    {/*
    <Form.Group controlId="imageFile3">
      <Form.Label>Upload imageFile</Form.Label>
      <Form.Control
        type="file"
        
        accept="image/*"
        onChange={handleGalleryFileChange}
        required
      />
    </Form.Group>  */}
    </>
  );
};

export default Gallery;

