// import React, { useState } from 'react';
// import { connect } from 'react-redux';
// import { Button, Form } from 'react-bootstrap';
// import { addVideo, removeVideo } from '../Redux/actions/VideoActions'; // Adjust action import as per your actual implementation

// const VideoComponent = ({ videoUrls, addVideo, removeVideo }) => {
//   const [count, setCount] = useState(1); // State to hold the count

//   const handleCountChange = (e) => {
//     const { value } = e.target;
//     setCount(parseInt(value) || 1); // Ensure count is a number and at least 1
//   };

//   const handleVideoUrlChange = (e, index) => {
//     const url = e.target.value;
//     if (url) {
//       addVideo(url);
//     }
//   };

//   const renderVideoInputs = () => {
//     return Array.from({ length: count }).map((_, index) => (
//       <Form.Group key={index}>
//         <Form.Label>Video URL {index + 1}</Form.Label>
//         <Form.Control type="url" onChange={(e) => handleVideoUrlChange(e, index)} />
//         <Button variant="danger" onClick={() => removeVideo(index)}>Remove</Button>
//       </Form.Group>
//     ));
//   };

//   return (
//     <Form onSubmit={(e) => e.preventDefault()}>
//       <Form.Group controlId="countInput">
//         <Form.Label>Enter Count (max 10)</Form.Label>
//         <Form.Control
//           type="number"
//           value={count}
//           onChange={handleCountChange}
//           min="1"
//           max="10"
//         />
//       </Form.Group>
//       {renderVideoInputs()}
//     </Form>
//   );
// };

// const mapStateToProps = (state) => ({
//   videoUrls: state.video.urls,
// });

// const mapDispatchToProps = {
//   addVideo,
//   removeVideo,
// };

// export default connect(mapStateToProps, mapDispatchToProps)(VideoComponent);



import React from 'react'
import { Form } from 'react-bootstrap';

const VideoComponent = ({ ids, formData, handleChange }) => {
 
    const labels = {
        videoUrl1: 'Video Url1',
        videoUrl2: 'Video Url2',
        videoUrl3: 'Video Url3',
        
      };
    
      return ids.map((id) => (
        <Form.Group controlId={id} key={id}>
          <Form.Label>{labels[id]}</Form.Label>
          <Form.Control
            type="url"
            value={formData[id]}
            onChange={handleChange}
            placeholder={labels[id]}
            
          />
        </Form.Group>
      ));
  
}

export default VideoComponent