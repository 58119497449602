

import React, { useState } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://api.emedha.in/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      });

      if (response.ok) {
        alert('Password reset instructions sent to your email.');
        setError('');
        localStorage.removeItem('token')
       

      } else {
        const data = await response.json();
        setError(data.error || 'Failed to send password reset email. Please try again.');
        setSuccessMessage('');
      }
    } catch (error) {
      console.error('Error sending password reset email:', error);
      setError('Failed to send password reset email. Please try again.');
      setSuccessMessage('');
    }
  };

  const [active,setActive]=useState(false)
  const onChangeCaptcha=()=>{
    setActive(!active)
  }

  return (
    <Container className="mt-5">
      <div className="forgot-password-form shadow p-4 rounded">
        <h2 className="text-center mb-4">Forgot Password</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="email">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>

          {error && <Alert variant="danger">{error}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}
          <div className="text-center mt-3 mb-2 g-recaptcha">
            <ReCAPTCHA
              sitekey="6LcKzpknAAAAAM3IGeFPi75Qh1HZDkuY9X7wDLfo"
              onChange={onChangeCaptcha}
            />
          </div>

          <Button variant="primary" type="submit" className="w-100 mt-3">
            Send Reset Instructions
          </Button>
        </Form>
      </div>
    </Container>
  );
};

export default ForgotPasswordForm;
