import React from 'react';
import { Form } from 'react-bootstrap';

const AboutText = ({ formData, handleChange }) => {
  return (
    <Form.Group controlId="about">
      <Form.Label>About</Form.Label>
      <Form.Control
         as="textarea"
         rows={3}
        value={formData.about}
        onChange={handleChange}
        placeholder="Enter your text"
        
      />
    </Form.Group>
  );
};

export default AboutText;
