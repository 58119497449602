import React, { useEffect, useState } from 'react';

import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, Redirect,Navigate } from 'react-router-dom';
import store from '../src/Redux/store';
import HomeComponent from './Components/HomeComponent';
import FormComponent from './Components/FormComponent';
import 'bootstrap/dist/css/bootstrap.min.css';
import SignUpForm from './Components/Authentication/Signup/Signup.js';
import LoginForm from './Components/Authentication/Login/Login.js';
import OtpVerificationForm from './Components/Authentication/OtpForm/Otp.js'
import ForgotPasswordForm from './Components/Authentication/ForgottenPassword/ForgotPasswordForm.js'
import PasswordResetForm from './Components/Authentication/PasswordResetFrom/PasswordResetFrom.js';
import axios from 'axios';





function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);  // Set isLoggedIn based on whether token exists
  }, []);

  const handleLogin = (token) => {
    localStorage.setItem('token', token);
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  };
 

  useEffect(() => {
    const fetchContactData = async () => {
      try {
        const response = await axios.get('http://localhost:3009/api/getform');
        setContacts(response.data); 
        console.log('Fetched contact data:', response.data);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.error('Error: Endpoint not found (404)');
        } else {
          console.error('Error fetching contact data:', error);
        }
        // Handle error appropriately, e.g., setContacts([]) or display an error message
      }
    };
  
    fetchContactData();
  }, []);


  



return (
  <Provider store={store}>
  <Router>
    <div>

      <main>
       
    <Routes>
      <Route path="/" element={<SignUpForm />} />
      <Route path="/otp-form" element={<OtpVerificationForm />} />
      <Route path="/forgot-password" element={<ForgotPasswordForm />} />
      <Route path="/reset-password" element={<PasswordResetForm />} />
      <Route path="/reset-password/:token" element={<PasswordResetForm />} />
    
      <Route
        path="/login"
        element={isLoggedIn ? <Navigate to="/form" /> : <LoginForm onLogin={handleLogin} />}
      />
    
      <Route path="/*" element={<Navigate to="/login" />} />
 

        <Route path="/:websiteUrl" element={<HomeComponent />} />
          <Route path="/form" element={<FormComponent />} />
         
        </Routes>
      </main>

    </div>
 
  </Router>
</Provider>
);


}

export default App;


         

