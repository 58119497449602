import { ADD_FILE, REMOVE_FILE, CLEAR_FILES } from '../actions/types';

const initialState = {
  files: [],
};

const galleryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FILE:
      return {
        ...state,
        files: [...state.files, action.payload],
      };
    case REMOVE_FILE:
      return {
        ...state,
        files: state.files.filter((file, index) => index !== action.payload),
      };
    case CLEAR_FILES:
      return {
        ...state,
        files: [],
      };
    default:
      return state;
  }
};

export default galleryReducer;
