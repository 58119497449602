import React from 'react';
import { Form } from 'react-bootstrap';

const Address = ({ formData, handleChange }) => {
  return (
    <Form.Group controlId="address">
      <Form.Label>Address</Form.Label>
      <Form.Control
        as="textarea"
        rows={4}
        value={formData.address}
        onChange={handleChange}
        placeholder="Enter Your Address"
        
      />
    </Form.Group>
  );
};

export default Address;
