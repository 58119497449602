// import React from 'react';
// import { connect } from 'react-redux';
// import { getFormData } from '../Redux/selectors/formSelectors';
// import { Button, Card, CardHeader, Col, Container, Nav, Navbar, Row } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
// import './HomeComponent.css'
// import { SiGoogledisplayandvideo360 } from "react-icons/si";
// import { MdSlowMotionVideo } from "react-icons/md";
// import { ImLocation } from "react-icons/im";
// import { TiLocationArrow } from "react-icons/ti";
// import { IoLogoWhatsapp } from "react-icons/io";
// import { TbBrandWhatsapp } from "react-icons/tb";
// import { RiContactsBookLine } from "react-icons/ri";
// import { RiContactsBook3Fill } from "react-icons/ri";
// import ExpandableText from './ExpandableText';
// import { FaFacebook ,FaLinkedin,FaInstagram,FaTwitter   } from "react-icons/fa";
// import ReactPlayer from 'react-player';
// import { FcCallback } from "react-icons/fc";
// import { TbWorld } from "react-icons/tb";

// const HomeComponent = ({ formData }) => {


// Q

  
//   return (
//     <Container>
// {/* <Navbar bg="light" variant="light" expand="lg"style={{  border: '0.5px solid black', borderRadius:'5px' ,padding:'5px',margin:'9px' }}>
//       <Navbar.Brand as={Link} to="/">
//       <Col xs="auto" style={{  display: 'flex', alignItems: 'center' }}>  {formData.imageFile && (
//             <img
//               src={URL.createObjectURL(formData.imageFile)}
//               alt="logo"
//               style={{ maxWidth: '350px',}} 
//             />
//           )}
//           </Col>
//           </Navbar.Brand>
//       <Navbar.Toggle aria-controls="basic-navbar-nav" />
//       <Navbar.Collapse id="basic-navbar-nav">
//         <Nav className="mx-auto text-center">
//           <Nav.Link as={Link} to="/" className="mb-2 mb-lg-0">
//             <span style={{ color: 'black', fontSize: '28px', fontWeight: 'bold' }}>{formData.companyName}</span>
//           </Nav.Link>
//           <Nav.Link as={Link} to="/form" className="mb-2 mb-lg-0">
//             <span style={{ color: 'black', fontSize: '20px', fontWeight: 'bold',marginLeft:'360px' }}>Form</span>
//           </Nav.Link>
//         </Nav>
//       </Navbar.Collapse>
//     </Navbar>*/}
 
   
        
         

     
//      {/*      
//       // <ul>
//       //   {['videoUrl', 'locationUrl', 'whatsappUrl','contactUrl'].map((key) => (
//       //     <li key={key}>
//       //       {`URL ${key.slice(-1)}: ${formData[key]}`}
//       //     </li>
//       //   ))}
//       // </ul> */ }

// <div >

//   <Row style={{padding:'20px'}}>
//   <Col lg={3} md={4} sm={6} className="mb-4" style={{}}>
//       {['videoUrl',].map((key) => (
//         <Card className="card-shadow"style={{color:'#2c2e8e',backgroundColor :'skyblue' }}>
//           <Card.Body>
//             <Card.Header><h1>View Our Video's <MdSlowMotionVideo style={{fontSize:'40px'}} /></h1> </Card.Header>
//             <br></br>
//             <Card.Title> <SiGoogledisplayandvideo360 style={{fontSize:'30px'}}/>  Video Link</Card.Title>
          
//             {/* <Card.Text>{formData.videoUrl}</Card.Text> */}
//             <Card.Text> <Button variant="primary"  href={formData.videoUrl} target="_blank">Click Here</Button></Card.Text>
//           </Card.Body>
//         </Card>
//      ))}
//   </Col>

//   <Col lg={3} md={4} sm={6} className="mb-4">
//       {['locationUrl',].map((key) => (
//         <Card className="card-shadow"style={{color:'#2c2e8e',backgroundColor :'skyblue' }}>
//           <Card.Body>
//           <Card.Header><h1>Get Our Location <ImLocation style={{fontSize:'40px'}}/></h1></Card.Header>
//           <br></br>
//             <Card.Title><TiLocationArrow style={{fontSize:'33px'}} />Location Link</Card.Title>
         
//          <Card.Text>  <Button variant="primary" href={formData.locationUrl} target="_blank">Click Here</Button></Card.Text>
        
//           </Card.Body>
//         </Card>
//         ))}
//       </Col>

//       <Col lg={3} md={4} sm={6} className="mb-4">
//       {['whatsappUrl',].map((key) => (
//            <Card className="card-shadow" style={{color:'#2c2e8e',backgroundColor :'skyblue' }}>
//           <Card.Body>
//             <CardHeader><h1>ChatVia Whatsapp<IoLogoWhatsapp style={{fontSize:'40px' }}/></h1>   </CardHeader>
//             <br></br>
//             <Card.Title><TbBrandWhatsapp style={{fontSize:'30px'}}/> With your Own account</Card.Title>
//             <Card.Text><Button variant="primary" href={formData.whatsappUrl} target="_blank">Click Here</Button>
//             </Card.Text>
//    </Card.Body>
//         </Card>
//         ))}
//       </Col>
         

//       <Col lg={3} md={4} sm={6} className="mb-4">
//       {['contactUrl',].map((key) => (
//         <Card className="card-shadow" style={{color:'#2c2e8e',backgroundColor :'skyblue' }}>
//           <Card.Body>
//             <Card.Header><h1>Save My Number <RiContactsBook3Fill  style={{fontSize:'40px'}}/></h1></Card.Header>
//             <br></br>
//             <Card.Title><RiContactsBookLine style={{fontSize:'30px'}}/>  Directly into your Mobile</Card.Title>
//             <Card.Text> <Button variant="primary" href={formData.contactUrl} target="_blank">Click Here</Button> </Card.Text>
//             </Card.Body>
//         </Card>
//          ))}
//       </Col>
      
// </Row>
// </div>



//       <div  style={{padding:'25px'}} >
     
//        <h1>About:</h1> 
//        <div style={{backgroundColor:'lightblue'}} >
       
//           <ExpandableText text={formData.about} maxChars={100} /> 
//           </div>        
//         </div>   


//       {/* <ul>
//         {['facebookUrl'].map((key) => (
//           <li key={key}>
//             {`Social Media ${key.slice(-1)}: ${formData[key]}`}
//           </li>
//          ))}
//       </ul>   
//        <ul>
//        {[ 'linkedinUrl'].map((key) => (
//          <li key={key}>
//            {`Social Media ${key.slice(-1)}: ${formData[key]}`}
//          </li>
//         ))}
//      </ul>  
//       <ul>
//       {[ 'instagramUrl'].map((key) => (
//         <li key={key}>
//           {`Social Media ${key.slice(-1)}: ${formData[key]}`}
//         </li>
//        ))}
//     </ul>  
//      <ul>
//      {[ 'twitterUrl'].map((key) => (
//        <li key={key}>
//          {`Social Media ${key.slice(-1)}: ${formData[key]}`}
//        </li>
//       ))}
//    </ul>   */}
//     <div className="social-media-container"style={{justifyContent:'space-between'}}>
 
//     <Row className="justify-content-center">
//     <h1>Social Space</h1>
//     <Col xs="auto" className="social-media-card ">
              
//                 <a variant="primary" href={formData['facebookUrl']} target="_blank">  <h3><FaFacebook style={{fontSize:'50px'}}/></h3> </a>
               
//                 </Col>
//                 <Col xs="auto" className="social-media-card ">
              
//                   <a variant="primary" href={formData['linkedinUrl']} target="_blank">  <h3><FaLinkedin style={{fontSize:'50px'}}/></h3> </a>
              
//                   </Col>
//                   <Col xs="auto" className="social-media-card ">
//             <a variant="primary" href={formData['instagramUrl']} target="_blank">  <h3><FaInstagram style={{fontSize:'50px'}} /></h3> </a>
                
               
//             </Col>
//             <Col xs="auto" className="social-media-card ">
//             <a variant="primary" href={formData['twitterUrl']} target="_blank">  <h3><FaTwitter style={{fontSize:'50px'}} /></h3> </a>
             
                
//             </Col>
//             </Row>
//         </div>
       

   



//         {/* {formData.imageFile1 && (
//         <div>
//           <img
//             src={URL.createObjectURL(formData.imageFile1)} 
//             alt="imagefile"
//             style={{ width: '350px', height: '100px' }}
//           />
         
//         </div>
//       )}

//         {formData.imageFile2 && (
//         <div>
//           <img
//             src={URL.createObjectURL(formData.imageFile2)} 
//             alt="imagefile"
//             style={{ width: '350px', height: '100px' }}
//           />
         
//         </div>
//       )} */}
// <Container>
// <Row className="justify-content-center">
//   <h1>Gallery</h1>
//       {formData.imageFile1 && (
//         <Col md={4} className="mb-4">
//           <Card>
//             <Card.Img src={URL.createObjectURL(formData.imageFile1)} alt="imagefile1" />
//             <Card.Body>
//               <Card.Title></Card.Title>
//             </Card.Body>
//           </Card>
//         </Col>
//       )}
//       {formData.imageFile2 && (
//         <Col md={4} className="mb-4">
//           <Card>
//             <Card.Img src={URL.createObjectURL(formData.imageFile2)} alt="imagefile2" />
//             <Card.Body>
//               <Card.Title></Card.Title>
//             </Card.Body>
//           </Card>
//         </Col>
//       )}
//     </Row>
//     </Container>

//     {/* <ul>
//         {['videoUrl1'].map((key) => (
//           <li key={key}>
//             {`URL ${key.slice(-1)}: ${formData[key]}`}
//           </li>
//         ))}
//       </ul> */}


// <div >
// <Container>
//       <Row className="justify-content-center">
//         <h1>My Videos</h1>
//         {['videoUrl3'].map((key, index) => (
//           <Col key={index} md={4} className="mb-4">
//             <div className="video-wrapper">
//               <ReactPlayer
//                 url={`${key.slice(-1)}: ${formData[key]}`}
//                 controls
//                 width="100%"
//                 height="100%"
//                 style={{ borderRadius: '8px' }}
//               />
//             </div>
//           </Col>
//         ))}
   
//         {[ 'videoUrl2'].map((key, index) => (
//           <Col key={index} md={4} className="mb-4">
//             <div className="video-wrapper">
//               <ReactPlayer
//                 url={formData[key]}
//                 controls
//                 width="100%"
//                 height="100%"
//                 style={{ borderRadius: '8px' }}
//               />
//             </div>
//           </Col>
//         ))}
    
//         {['videoUrl3'].map((key, index) => (
//           <Col key={index} md={4} className="mb-4">
//             <div className="video-wrapper">
//               <ReactPlayer
//                 url={formData[key]}
//                 controls
//                 width="100%"
//                 height="100%"
//                 style={{ borderRadius: '8px' }}
//               />
//             </div>
//           </Col>
//         ))}
//       </Row>

//     </Container>
//      </div>


    
//       {/* <ul>
//         {['contactUrl'].map((key) => (
//           <li key={key}>
//             {`Footer ${key.slice(-1)}: ${formData[key]}`}
//           </li>
//         ))}
//       </ul>
//       <ul>
//         {['locationUrl1'].map( (key) => (
//           <li key={key}>
//             { `Footer ${key.slice(-1)}: ${formData[key]}`}
//           </li>
//         ))}
//       </ul> */}


//       <Container>
//       <Row className="justify-content-center">
//         <h1>Contact</h1>
//         <Col md={6} className="mb-4">
//           <Card style={{backgroundColor:'lightblue',alignItems:'center'}}>
//             <Card.Body>
//             <Card.Header><a href={`tel:${formData['contactUrl']}`} target="_blank" rel="noopener noreferrer"><FcCallback style={{fontSize:'50px'}}/> </a></Card.Header>
//               <Card.Title>  
//               </Card.Title>
//               <Card.Text>+91 9849164361</Card.Text>
//             </Card.Body>
//           </Card>
//         </Col>
//         <Col md={6} className="mb-4">
//           <Card  style={{backgroundColor:'lightblue',alignItems:'center'}}>
//             <Card.Body>
//             <Card.Header><a href={formData['locationUrl1']} target="_blank" rel= "noopener noreferrer "> <TbWorld style={{fontSize:'50px'}} /> </a></Card.Header>
//               <Card.Title></Card.Title>
//               <Card.Text>
//                info@emedha.com
//                Unit # 208, 2nd Floor, Ashoka Bhupal Chambers, 
//                S.P.Road, Secunderabad - 500 003, Telangana, INDIA
                
//               </Card.Text>
//             </Card.Body>
//           </Card>
//         </Col>
//       </Row>
//     </Container>
    

//     </Container> 
//   );
// };

// const mapStateToProps = (state) => ({
//   formData: getFormData(state),

  
// });

// export default connect(mapStateToProps)(HomeComponent);





import React, { useEffect, useState } from 'react';

// import { connect } from 'react-redux';
// import { getFormData } from '../Redux/selectors/formSelectors';
import { Button, Card, CardHeader, Col, Container, Nav, Navbar, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import './HomeComponent.css'
import { SiGoogledisplayandvideo360 } from "react-icons/si";
import { MdSlowMotionVideo } from "react-icons/md";
import { ImLocation } from "react-icons/im";
import { TiLocationArrow } from "react-icons/ti";
import { IoLogoWhatsapp } from "react-icons/io";
import { TbBrandWhatsapp } from "react-icons/tb";
import { RiContactsBookLine } from "react-icons/ri";
import { RiContactsBook3Fill } from "react-icons/ri";
import ExpandableText from './ExpandableText';
import YouTube from 'react-youtube';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactPlayer from 'react-player';
import { FcCallback } from "react-icons/fc";
import { TbWorld } from "react-icons/tb";
import video from './image/video.png';
import contact from './image/contact.png';
import whatsapp from './image/whatsapp.png';
import location from './image/location.png';
import { fabric } from 'fabric';
import { FaCopy, FaEnvelope, FaShareAlt } from "react-icons/fa";
import { Canvas, Rect, Ellipse } from 'fabric';
import { FaFacebook, FaLinkedin, FaInstagram, FaTwitter } from "react-icons/fa";

import { useParams } from 'react-router-dom';
import axios from 'axios'
import { Modal } from 'react-bootstrap';
import {  FaTelegram } from 'react-icons/fa';
import {  FacebookShareButton, TwitterShareButton, TelegramShareButton, WhatsappShareButton,FacebookIcon,TwitterIcon,TelegramIcon,WhatsappIcon, LinkedinIcon, LinkedinShareButton}  from 'react-share';


const HomeComponent = () => {


  
  const [websiteData, setWebsiteData] = useState(null);
  const [contacts, setContacts] = useState([]);
  
 

const navigate =useNavigate()
  const { websiteUrl } = useParams();
   
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setShowModal(true);
  };

  const handleCloseModall = () => {
    setShowModal(false);
    setSelectedImage(null);
  };

  // useEffect(() => {
  //   // Initialize Fabric.js canvas
  //   const canvas = new fabric.Canvas('canvas', {
  //     width: 800,
  //     height: 600,
  //     backgroundColor: '#f0f0f0'
  //   });

  //   // Add logo image to the canvas
  //   const logoImage = new fabric.Image.fromURL(websiteData.logo, function(img) {
  //     img.set({
  //       left: 100,
  //       top: 100,
  //       scaleX: 0.5,
  //       scaleY: 0.5
  //     });
  //     canvas.add(img);
  //   });

  //   // Add video image to the canvas
  //   const imageFile1 = new fabric.Image.fromURL(websiteData.imageFile1, function(img) {
  //     img.set({
  //       left: 300,
  //       top: 100,
  //       scaleX: 0.5,
  //       scaleY: 0.5
  //     });
  //     canvas.add(img);
  //   });

  //   // Add location image to the canvas
  //   const locationImage = new fabric.Image.fromURL(location, function(img) {
  //     img.set({
  //       left: 500,
  //       top: 100,
  //       scaleX: 0.5,
  //       scaleY: 0.5
  //     });
  //     canvas.add(img);
  //   });

  //   // Add contact image to the canvas
  //   const contactImage = new fabric.Image.fromURL(contact, function(img) {
  //     img.set({
  //       left: 100,
  //       top: 300,
  //       scaleX: 0.5,
  //       scaleY: 0.5
  //     });
  //     canvas.add(img);
  //   });

  //   // Add WhatsApp image to the canvas
  //   const whatsappImage = new fabric.Image.fromURL(whatsapp, function(img) {
  //     img.set({
  //       left: 300,
  //       top: 300,
  //       scaleX: 0.5,
  //       scaleY: 0.5
  //     });
  //     canvas.add(img);
  //   });

  //   // Event handling example
  //   logoImage.on('mousedown', function(options) {
  //     console.log('Logo image clicked!', options.e.clientX, options.e.clientY);
  //   });

  //   // Clean up function
  //   return () => {
  //     canvas.dispose(); // Clean up Fabric.js canvas
  //   };
  // }, [websiteData]); // Depend on websiteData to update canvas when logo changes
 
  // const [modalOpen, setModalOpen] = useState(false);
  
  // const handleShareButtonClick = () => {
  //   setModalOpen(true);
  // };

  // const handleCloseModal = () => {
  //   setModalOpen(false);
  // };
  
  
  const [modalOpen, setModalOpen] = useState(false);
const shareUrl = `https://digicard.emedha.in/${websiteUrl}`; // Replace with your website URL
  const shareTitle = 'Check out this website!'; // Replace with your website title

  const handleShareButtonClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`https://digicard.emedha.in/${websiteUrl}`);
    alert('Link copied to clipboard!');
  };

  // const openEmailClient = () => {
  //   const subject = 'Check out this profile!';
  //   const body = `Hi there,\n\nI found this interesting profile: ${websiteUrl}\n\nCheck it out!\n`;
  //   const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  //   window.location.href = mailtoLink;
  // };
  const openEmailClient = () => {
    const websiteData = {
      contactEmail: '{websiteData.contactEmail}' 
      
    };
  
    const subject = 'Check out this profile!';
    const body = `Hi there,\n\nI found this interesting profile: ${websiteUrl}\n\nCheck it out!\n`;
    const mailtoLink = `mailto:${websiteData.contactEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };



  useEffect(() => {
    const fetchWebsiteData = async () => {
      try {
        const response = await axios.get(`https://api.emedha.in/${websiteUrl}`);
        if (Array.isArray(response.data) && response.data.length > 0) {
          setWebsiteData(response.data[0]); // Extract the first object from the array
        } else {
          setWebsiteData(response.data);
        }
        console.log('Fetched data:', response.data); // Ensure data is logged correctly
      } catch (error) {
        console.error('Error fetching website data:', error);
      }
    };

    fetchWebsiteData();
  }, [websiteUrl]);
  

  useEffect(() => {
    const fetchContactData = async () => {
      try {
        const response = await axios.get('http://localhost:3009/get-form-data');
        setContacts(response.data); // Assuming response.data is an array of contacts
        console.log('Fetched contact data:', response.data);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.error('Error: Endpoint not found (404)');
        } else {
          console.error('Error fetching contact data:', error);
        }
        // Handle error appropriately, e.g., setContacts([]) or display an error message
      }
    };
  
    fetchContactData();
  }, []);
  

  if (!websiteData) {
    return <div>Loading...</div>;
  }

  console.log(websiteData)


//   const downloadVCard = () => {
//     // Construct vCard content
//     const vCardContent = `BEGIN:VCARD
// VERSION:3.0

// ORG:${websiteData.organization}
// FN:${websiteData.name}
// TEL:${websiteData.contactNumber}
// EMAIL:${websiteData.contactEmail}
// BDAY:${websiteData.dateOfBirth}
// END:VCARD`;
// const blob = new Blob([vCardContent], { type: 'text/VCard' });

// // Create a URL for the Blob
// const url = window.URL.createObjectURL(blob);

// // Create a link element
// const link = document.createElement('a');
// link.href = url;
// link.setAttribute('download', 'contact.vcf'); // Set the file name here

// // Append the link to the body
// document.body.appendChild(link);

// // Click the link programmatically to trigger the download
// link.click();

// // Clean up
// document.body.removeChild(link);
// };

const downloadVCard = () => {
  // Construct vCard content
  const vCardContent = `BEGIN:VCARD
VERSION:3.0
TITLE:${websiteData.name || ''}
ORG:${websiteData.organization || ''}
TEL:${websiteData.contactNumber || ''}
EMAIL:${websiteData.contactEmail || ''}
BDAY:${formatDateForVCard(websiteData.dateOfBirth)}
END:VCARD`;

  const blob = new Blob([vCardContent], { type: 'text/vcard' });

  // Create a URL for the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'contact.vcf'); // Set the file name here

  // Append the link to the body
  document.body.appendChild(link);

  // Click the link programmatically to trigger the download
  link.click();

  // Clean up
  document.body.removeChild(link);
};

const formatDateForVCard = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};



  return (
    <>
    < Container style={{ maxWidth:'100%' }}>
       {/* Header Section */}
   
       <Navbar expand="lg"  style={{ backgroundColor: 'white', borderRadius: '5px', padding: '5px', margin: '9px' }}>
      <Navbar.Brand as={Link} to="/home" className="mr-auto ">
        <img src={websiteData.logo} alt="Logo" style={{ width: '350px', height: 'auto' , border: '2px solid #fff',   boxShadow: '10 4px 8px rgba(0,0,0,0.1)' }} />
      </Navbar.Brand>
      <Col xs={12} lg={7} className="text-center">
        <h1 style={{color:'#0282ae'}}>{websiteData.companyName}</h1>
      </Col>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link >
            <a btn='primary' onClick={handleShareButtonClick} style={{ color: 'black', fontSize: '20px', fontWeight: 'bold' }}><FaShareAlt/></a>
          </Nav.Link>
          <Modal show={modalOpen} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Share this Website</Modal.Title>
        </Modal.Header>
        <Modal.Body className='' >
          <FacebookShareButton url={shareUrl} quote={shareTitle}>
            <FacebookIcon size={40} round className='m-2 text-center' />
          </FacebookShareButton>

          <TwitterShareButton url={shareUrl} title={shareTitle}>
            <TwitterIcon size={40} round className='m-2' />
          </TwitterShareButton>

          <TelegramShareButton url={shareUrl} title={shareTitle}>
            <TelegramIcon size={40} round className='m-2' />
          </TelegramShareButton>

          <WhatsappShareButton url={shareUrl} title={shareTitle}>
            <WhatsappIcon size={40} round className='m-2' />
          </WhatsappShareButton><br></br>
      

          <Button variant="light" onClick={copyToClipboard}>
            <FaCopy size={24} style={{ verticalAlign: 'middle', marginRight: '5px' }} /> Copy link
          </Button>

          <Button variant="light" onClick={openEmailClient}>
            <FaEnvelope size={24} style={{ verticalAlign: 'middle', marginRight: '5px' }} /> Email to myself
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
          {/* <Modal show={modalOpen} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Share this website</Modal.Title>
        </Modal.Header>
        <Modal.Body className='m-5 p-3' >
          <FacebookShareButton url={shareUrl} quote={shareTitle}>
            <FacebookIcon size={40} round className='m-2 text-left' />
          </FacebookShareButton>

          <TwitterShareButton url={shareUrl} title={shareTitle}>
            <TwitterIcon size={40} round className='m-2' />
          </TwitterShareButton>

          <TelegramShareButton url={shareUrl} title={shareTitle}>
            <TelegramIcon size={40} round className='m-2' />
          </TelegramShareButton>

          <WhatsappShareButton url={shareUrl} title={shareTitle}>
            <WhatsappIcon size={40} round className='m-2' />
          </WhatsappShareButton>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
   
</Container>
    
    <div class='container p-2 '  >
      {/* Main Content Section */}
      <Row className="mt-3">
        {/* Video Section */}
        <Col md={3} className="mb-3">
          <Card style={{backgroundColor:'lightsteelblue'}}>
            <Card.Body>
            <Card.Header><h1 className='text-center'><img src={video} alt='img' style={{height:'100px'}}/></h1></Card.Header>
              <Card.Title className='text-center'>View Our Videos <MdSlowMotionVideo style={{fontSize:'30px'}}/></Card.Title><br></br>
              <Card.Text>
                {/* You can use ReactPlayer for playing videos */}
                <Button variant="outline-danger" href={websiteData.videoUrl} target="_blank">Watch Video</Button>
                
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} className="mb-3">
          <Card>
            <Card.Body style={{backgroundColor:'lightsteelblue'}}>
            <Card.Header><h1 className='text-center'><img src={location} alt='img'style={{height:'100px'}}/> </h1></Card.Header>
              <Card.Title className='text-center'>Get Our Location <TiLocationArrow style={{fontSize:'30px'}}/></Card.Title><br></br>
              <Card.Text>
              <Button variant="outline-success" href={websiteData.locationUrl} target="_blank">Location</Button>  {' '}
             
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>



        <Col md={3} className="mb-3">
      <Card>
        <Card.Body style={{ backgroundColor: 'lightblue' }}>
          <Card.Header>
            <h1 className='text-center'><img src={contact} style={{ height: '100px' }} alt="Contact Icon" /></h1>
          </Card.Header>
          <Card.Title className='text-center'>Save Number (Directly into your Mobile) <RiContactsBookLine style={{fontSize:'30px'}} /></Card.Title>
          <Card.Text>
            {/* Example of a Button with combined functionality */}
            <Button variant="outline-primary" onClick={downloadVCard}>
              Download vCard
            </Button>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>

        
        <Col md={3} className="mb-3">
          <Card>
            <Card.Body style={{backgroundColor:'lightblue'}}>
            <Card.Header><h1 className='text-center'><img src={whatsapp} style={{height:'100px'}}/> </h1></Card.Header>
              <Card.Title className='text-center'>Chat Via Whatsapp<br></br>with your Own account <TbBrandWhatsapp  style={{fontSize:'30px'}}/></Card.Title>
              <Card.Text>
              <Button variant="outline-success"  href={websiteData.whatsappUrl} target="_blank">
                  <IoLogoWhatsapp /> WhatsApp
                </Button>{' '}
                {/* You can use ReactPlayer for playing videos */}
                {/* <Button variant="primary" href={websiteData.videoUrl} target="_blank">Watch Video</Button>
                <Button variant="secondary" className="ml-2" href={websiteData.videoUrl1} target="_blank">Video 1</Button>
                <Button variant="secondary" className="ml-2" href={websiteData.videoUrl2} target="_blank">Video 2</Button>
                <Button variant="secondary" className="ml-2" href={websiteData.videoUrl3} target="_blank">Video 3</Button> */}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        {/* About Section */}
        <Col md={12} className="mb-3">
          <Card style={{backgroundColor:'lightblue'}}>
            <Card.Body>
              <Card.Title>About Us</Card.Title>
              <Card.Text> <ExpandableText text={websiteData.about} maxChars={100} /></Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/*  Social Media Section */}
      <Row className="mt-2">
       
      <Col xs={6} sm={3} md={3} lg={3} xl={3} className="mb-2">
          <Card className='text-center'  style={{backgroundColor:''}}>
            <Card.Body>
             
              <Card.Text>
                <Button variant="outline-primary" href={websiteData.facebookUrl} target="_blank"><FaFacebook style={{fontSize:'30px'}}/></Button>{' '}
               
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={6} sm={3} md={3} lg={3} xl={3} className="mb-2">
          <Card className='text-center'  style={{backgroundColor:''}}>
            <Card.Body>
            
              <Card.Text>
                
                <Button variant="outline-info" href={websiteData.linkedinUrl} target="_blank"><FaLinkedin style={{fontSize:'30px'}}/></Button>{' '}
              
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={6} sm={3} md={3} lg={3} xl={3} className="mb-2">
          <Card  style={{backgroundColor:''}}>
            <Card.Body className='text-center'>
           
              <Card.Text >
                
                <Button variant="outline-danger" href={websiteData.instagramUrl} target="_blank"><FaInstagram style={{fontSize:'30px'}}/></Button>{' '}
               
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={6} sm={3} md={3} lg={3} xl={3} className="mb-2">
          <Card className='text-center' style={{backgroundColor:''}}>
            <Card.Body>
              
              <Card.Text>
               
                <Button variant="outline-info" href={websiteData.twitterUrl} target="_blank"><FaTwitter style={{fontSize:'30px'}}/></Button>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Image Gallery Section */}
       <Row className="mt-3">
        <h2>Image Gallery</h2>
        <Col md={6} className="mb-3 text-center">
          <Card>
            <Card.Body>
              <Card.Title></Card.Title>
              <Card.Img
                variant="top"
                src={websiteData.imageFile1}
                style={{ maxWidth: '200px', height: 'auto', cursor: 'pointer' }}
                onClick={() => handleImageClick(websiteData.imageFile1)}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="mb-3 text-center">
          <Card>
            <Card.Body>
              <Card.Title></Card.Title>
              <Card.Img
                variant="top"
                src={websiteData.imageFile2}
                style={{ maxWidth: '200px', height: 'auto', cursor: 'pointer' }}
                onClick={() => handleImageClick(websiteData.imageFile2)}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal show={showModal} onHide={handleCloseModall} centered>
        <Modal.Body className="text-center">
          <img src={selectedImage} alt="Large Image" style={{ maxWidth: '100%', height: 'auto' }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModall}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Row className="mt-3 ">
      <h2>Videos</h2>
      <Col md={4} className="mb-3 text-center">
        <Card style={{maxWidth:'500px',height:'auto'}}>
          <Card.Body>
            <Card.Title> 
            <div className="embed-responsive embed-responsive-16by9">
                  <ReactPlayer
                    url={websiteData.videoUrl1}
                    width="100%"
                    height="100%"
                    controls={true} // Show player controls (play, pause, etc.)
                  />
                </div>
               </Card.Title>
           
          </Card.Body>
        </Card>
      </Col>
      <Col md={4} className="mb-3 text-center">
        <Card>
          <Card.Body>
            <Card.Title>
            <div className="embed-responsive embed-responsive-16by9">
                  <ReactPlayer
                    url={websiteData.videoUrl2}
                    width="100%"
                    height="100%"
                    controls={true} // Show player controls (play, pause, etc.)
                  />
                </div>
              </Card.Title>
            
          </Card.Body>
        </Card>
      </Col>
      <Col md={4} className="mb-3 text-center">
        <Card>
          <Card.Body>
            <Card.Title> <div className="embed-responsive embed-responsive-16by9">
                  <ReactPlayer
                    url={websiteData.videoUrl3}
                    width="100%"
                    height="100%"
                    controls={true} // Show player controls (play, pause, etc.)
                  />
                </div>
                 </Card.Title>
           
          </Card.Body>
        </Card>
      </Col>
   
    </Row>



      {/* Location Section */}
      <Row className="mt-3">
        <Col md={6} className="mb-3">
          <Card style={{backgroundColor:'lightskyblue'}}>
            <Card.Body>
              <Card.Header className='text-center'><h1><FcCallback/></h1></Card.Header>
              <Card.Title className='text-center'>Our Contact </Card.Title> <br></br>
             
        <Card.Text>
                
     <a className='text-center'  href={`tel:${websiteData.contactNumber}`} style={{textDecoration:'none'}}>    <Button className='text-center' variant='outline-primary'> Call Now</Button></a>
         <p><br></br></p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="mb-3">
          <Card style={{backgroundColor:'lightskyblue'}}>
            <Card.Body>
              <Card.Header className='text-center'><h1> < TbWorld/></h1> </Card.Header>
            <Card.Title  className='text-center'>Our Location </Card.Title>
             <Card.Text className='text-center'> <p>{websiteData.address}</p></Card.Text>
              <Card.Text>
            
                <Button variant="outline-primary" href={websiteData.locationUrl1} target="_blank">Location</Button>{' '}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

      </Row>

       <Row className="scrolling-row">
      <Card style={{backgroundColor:'lightyellow' ,height:'40px' ,justifyContent:'center'}}>
        <Card.Body className="scrolling-body ">
          <Card.Title>
            <h5 className="scrolling-text">Available Timings: 24/7</h5>
          </Card.Title>
        </Card.Body>
      </Card>
    </Row>
    </div>
    </>
  );
};
    export default HomeComponent;
    
