import React, { useState } from 'react';


const ExpandableText = ({ text = '', maxChars }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    // Function to toggle the expanded state
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    // Truncate the text based on maxChars if not expanded
    const displayText = isExpanded ? text : text.slice(0, maxChars);

    return (
        <div>
            <p>{displayText}</p>
            {text && text.length > maxChars && (
                <button onClick={toggleExpand} className='text-end'> 
                    {isExpanded ? 'Read less' : 'Read more'}
                </button>
            )}
        </div>
    );
};

export default ExpandableText;
