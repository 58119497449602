// import React from 'react';
// import { Form } from 'react-bootstrap';

// const Footer = ({ ids, formData, handleChange }) => {
//   const labels = {
//     contactUrl1: 'Contact Url1',
//     locationUrl1:'Location Url1',
//   };
  
//   return ids.map((id) => (
//     <Form.Group controlId={id} key={id}>
//       <Form.Label>{labels[id]}</Form.Label>
//       <Form.Control
//         type="url"
//         value={formData[id]}
//         onChange={handleChange}
//         placeholder={labels[id]}
//         required
//       />
//     </Form.Group>
//   ));
// };


// export default Footer;


import React from 'react';
import { Form } from 'react-bootstrap';

const Footer = ({ ids, formData, handleChange }) => {
  const labels = {
    contactUrl1: 'Contact Url1',
    locationUrl1: 'Location Url1',
  };

  // Check if ids is defined and is an array
  if (!ids || !Array.isArray(ids)) {
    return null; // or return an appropriate fallback JSX
  }
  
  return (
    <>
      {ids.map((id) => (
        <Form.Group controlId={id} key={id}>
          <Form.Label>{labels[id]}</Form.Label>
          <Form.Control
            type="url"
            value={formData[id] || ''}
            onChange={(e) => handleChange(e)}
            placeholder={labels[id]}
            required
          />
        </Form.Group>
      ))}
    </>
  );
};

export default Footer;
