import React from 'react';
import { Form } from 'react-bootstrap';

const SocialMediaGroup = ({ ids, formData, handleChange }) => {
  const labels = {
    facebookUrl: 'Facebook URL',
    linkedinUrl: 'LinkedIn URL',
    instagramUrl: 'Instagram URL',
    twitterUrl: 'Twitter URL'
  };

  return ids.map((id) => (
    <Form.Group controlId={id} key={id}>
      <Form.Label>{labels[id]}</Form.Label>
      <Form.Control
        type="url"
        value={formData[id]}
        onChange={handleChange}
        placeholder={labels[id]}
        
      />
    </Form.Group>
  ));
};

export default SocialMediaGroup;
