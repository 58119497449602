import React from 'react';
import { Form } from 'react-bootstrap';

const CompanyName = ({ formData, handleChange }) => {
  return (
    <Form.Group controlId="companyName"> 
      <Form.Label>Company Name</Form.Label>
      <Form.Control
        type="text"
        value={formData.companyName} 
        onChange={handleChange} 
        placeholder="Enter your company name"
        required
      />
    </Form.Group>
  );
};

export default CompanyName;
