// import React, { useState } from 'react';
// import { Form, Button } from 'react-bootstrap';
// import { connect } from 'react-redux';
// import { submitForm } from '../Redux/actions/formActions';
// import ImageUpload from './ImageUpload';
// import URLGroup from './URLGroup';
// import SocialMediaGroup from './SocialMediaGroup';
// import AboutText from './AboutText';
// import Gallery from './Gallery';
// import CompanyName from './CompanyName';
// import VideoComponent from './VideoComponent';
// import Footer from './Footer';

// const FormComponent = ({ onSubmit }) => {
//   const [formData, setFormData] = useState({
//     imageFile: null,
//     companyName:'',
//     videoUrl: '',
//     locationUrl: '',
//     whatsappUrl: '',
//     contactUrl:'',
//     about: '',
//     facebookUrl: '',
//     linkedinUrl: '',
//     instagramUrl: '',
//     twitterUrl: '',
//     imageFile1:'',
//     imageFile2:null,
//     videoUrl1:'',
//     videoUrl2:'',
//     videoUrl3:'',
//     contactUrl1:'',
//     locationUrl1:'',

  
//   });

//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     setFormData({ ...formData, imageFile: file });
//   };
//   const handleFileChangee = (e) => {
//     const file = e.target.files[0];
//     setFormData({ ...formData, imageFile1: file });
//   };
//   const handleFileChangeee = (e) => {
//     const file = e.target.files[0];
//     setFormData({ ...formData, imageFile2: file });

//   };
 

//   const handleCountChange = (e) => {
//     const { value } = e.target;
//     setFormData({ ...formData, count: parseInt(value) || 1 }); // Update count in formData
//   };


//   const handleChange = (e) => {
//     const { id, value } = e.target;
//     setFormData({ ...formData, [id]: value });
//   };
  
//   // const handleVideoUrlsChange = (videoUrls) => {
//   //   setFormData({ ...formData, videoUrls });
//   // };


//   const handleSubmit = (e) => {
//     e.preventDefault();
//     onSubmit(formData);
//     setFormData({
//       imageFile: null,
//       companyName:'',
//       videoUrl: '',
//       locationUrl: '',
//       whatsappUrl: '',
//       contactUrl:'',
//       about: '',
//       facebookUrl: '',
//       linkedinUrl: '',
//       instagramUrl: '',
//       twitterUrl: '',
//       imageFile1:'',
//       imageFile2:null,
  
//       videoUrl1:'',
//       videoUrl2:'',
//       videoUrl3:'',
//       contactUrl1:'',
//       locationUrl1:'',
  
  
//     });
//   };
  
//   return (
//     <Form onSubmit={handleSubmit}>
//       <ImageUpload handleFileChange={handleFileChange} />
//       <CompanyName formData={formData} handleChange={handleChange} />
//       <URLGroup ids={['videoUrl', 'locationUrl','contactUrl','whatsappUrl']} formData={formData} handleChange={handleChange} />
//       <AboutText formData={formData} handleChange={handleChange} />
//       <SocialMediaGroup ids={['facebookUrl', 'linkedinUrl', 'instagramUrl', 'twitterUrl']} formData={formData} handleChange={handleChange} />
//       <Gallery handleFileChangee={handleFileChangee}  handleFileChangeee={handleFileChangeee}/>
//       <VideoComponent ids={['videoUrl1', 'videoUrl2', 'videoUrl3']} formData={formData} handleChange={handleChange} />
//       <Footer ids={['contactUrl1', 'locationUrl1']} formData={formData} handleChange={handleChange} />
//       <Button type="submit">Submit</Button>
     
//     </Form>
//   );
// };

// const mapDispatchToProps = (dispatch) => ({
//   onSubmit: (formData) => dispatch(submitForm(formData)),
// });

// export default connect(null, mapDispatchToProps)(FormComponent);



import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { submitForm } from '../Redux/actions/formActions';
import ImageUpload from './ImageUpload';
import URLGroup from './URLGroup';
import SocialMediaGroup from './SocialMediaGroup';
import AboutText from './AboutText';
import Gallery from './Gallery';
import CompanyName from './CompanyName';
import VideoComponent from './VideoComponent';
import Footer from './Footer';
import axios from 'axios';
import VcfCard from './VcfCard';
import { useHistory, useNavigate } from 'react-router-dom';
import Address from './Address'
import './FormComponent.css'
const FormComponent = ({ onSubmit }) => {

const [webData,setWebData]=useState(null)


  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    imageFile: null,
    companyName: '',
    videoUrl: '',
    locationUrl: '',
    whatsappUrl: '',
    name:'',
    organization:'',
    contactEmail: '',
    contactNumber:'',
    dateOfBirth:'',
    // contactUrl: '',
    about: '',
    facebookUrl: '',
    linkedinUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    imageFile1: null,
    imageFile2: null,
    videoUrl1: '',
    videoUrl2: '',
    videoUrl3: '',
    // contactUrl1: '',
    locationUrl1: '',
    address:'',
    websiteUrl: ''
  });

 

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, imageFile: file });
  };

  const handleFileChangee = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, imageFile1: file });
  };

  const handleFileChangeee = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, imageFile2: file });
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const loginEmail=localStorage.getItem('loginEmail')
console.log(loginEmail)
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('logo', formData.imageFile);
    formDataToSend.append('imageFile1', formData.imageFile1);
    formDataToSend.append('imageFile2', formData.imageFile2);
    formDataToSend.append('companyName', formData.companyName);
    formDataToSend.append('videoUrl', formData.videoUrl);
    formDataToSend.append('locationUrl', formData.locationUrl);
    formDataToSend.append('whatsappUrl', formData.whatsappUrl);
    formDataToSend.append('name', formData.name);
    formDataToSend.append('organization', formData.organization);
    formDataToSend.append('contactEmail', formData.contactEmail);
    formDataToSend.append('contactNumber', formData.contactNumber);
    formDataToSend.append('dateOfBirth', formData.dateOfBirth);
    // formDataToSend.append('contactUrl', formData.contactUrl);
    formDataToSend.append('about', formData.about);
    formDataToSend.append('facebookUrl', formData.facebookUrl);
    formDataToSend.append('linkedinUrl', formData.linkedinUrl);
    formDataToSend.append('instagramUrl', formData.instagramUrl);
    formDataToSend.append('twitterUrl', formData.twitterUrl);
    formDataToSend.append('videoUrl1', formData.videoUrl1);
    formDataToSend.append('videoUrl2', formData.videoUrl2);
    formDataToSend.append('videoUrl3', formData.videoUrl3);
    // formDataToSend.append('contactUrl1', formData.contactUrl1);
    formDataToSend.append('locationUrl1', formData.locationUrl1);
    formDataToSend.append('address', formData.address);
    formDataToSend.append('email',loginEmail)

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post('https://api.emedha.in/submit-form', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });

      // Adjust based on your actual response structure

      console.log('Form data submitted successfully', response.data);

      setFormData({ ...formData, websiteUrl: response.data.websiteUrl });
  alert('Form submitted successfully!');

      const websiteUrl = response.data.formData.websiteUrl;
      console.log('Website URL:',websiteUrl);

      // Navigate to the dynamically generated URL
      navigate(`/${websiteUrl}`);
      localStorage.removeItem('token');
      // Navigate to the dynamically generated URL
     
    } catch (error) {
      console.error('Error submitting form data', error);
      // Optionally handle error here
    }


    const handleChange = (e) => {
      const { id, value } = e.target;
      setFormData({ ...formData, [id]: value });
    };
    // Clear form state after submission
    setFormData({
      imageFile: null,
      companyName: '',
      videoUrl: '',
      locationUrl: '',
      whatsappUrl: '',
      name:'',
      organization:'',
      contactEmail: '',
      contactNumber:'',
      dateOfBirth:'',
      // contactUrl: '',
      about: '',
      facebookUrl: '',
      linkedinUrl: '',
      instagramUrl: '',
      twitterUrl: '',
      imageFile1: null,
      imageFile2: null,
      videoUrl1: '',
      videoUrl2: '',
      videoUrl3: '',
      // contactUrl1: '',
      address:'',
      locationUrl1: '',
    });
  };
 
  return (
    <div className="containerr">
      <h2> <img src="https://emedha.com/assets/img/logo3.png" alt="Emedha Logo" className="logo-img" />Form</h2>
    <Form className="form-containerr m-2" onSubmit={handleSubmit}>
      <div className='form-group'>
      <ImageUpload handleFileChange={handleFileChange} className='form-group '/>
      <CompanyName formData={formData} handleChange={handleChange} />
      <VcfCard ids={['name','organization','contactEmail','contactNumber','dateOfBirth']}  formData={formData} handleChange={handleChange} />
      <URLGroup ids={['videoUrl', 'locationUrl',  'whatsappUrl']} formData={formData} handleChange={handleChange} />
      <AboutText formData={formData} handleChange={handleChange} />
      <SocialMediaGroup ids={['facebookUrl', 'linkedinUrl', 'instagramUrl', 'twitterUrl']} formData={formData} handleChange={handleChange} />
      <Gallery handleFileChangee={handleFileChangee} handleFileChangeee={handleFileChangeee} />
      <VideoComponent ids={['videoUrl1', 'videoUrl2', 'videoUrl3']} formData={formData} handleChange={handleChange} />
      <Footer ids={['locationUrl1']} formData={formData} handleChange={handleChange} />
      <Address formData={formData} handleChange={handleChange}/> <br></br>
   <button className="btn-submit " onSubmit={handleSubmit}> Submit</button> 
   </div>
    </Form>
    </div>

  );
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (formData) => dispatch(submitForm(formData)),
});

export default connect(null, mapDispatchToProps)(FormComponent);
