import { combineReducers } from 'redux';
import formReducer from './formReducer';
import galleryReducer from './galleryReducer';

const rootReducer = combineReducers({
  form: formReducer,
  gallery: galleryReducer,
});

export default rootReducer;
